import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Container from "../../components/Container";
import SEO from "../../components/SEO";
import PageHeader from "../../components/PageHeader";
import SectionHeader from "../../components/SectionHeader";
import Row from "../../components/Row";
import Column from "../../components/Column";
import Card from "../../components/Card";
import CareersBanner from "../../components/CareersBanner";
import { Link } from "gatsby";

export default ({ data }) => {
  const years = [];
  const nodes = data.allMarkdownRemark.edges.reduce((prev, current) => {
    const year = current.node.frontmatter.year;

    (prev[year] = prev[year] || []).push(current.node);

    if (!years.includes(year)) {
      years.push(year);
    }

    return prev;
  }, {});

  return (
    <>
      <SEO description="株式会社SocialDogのニュース。" title="ニュース" />
      <PageHeader title="News" lead="ニュース" />
      {years.map(year => (
        <Container key={year}>
          <Row>
            <Column col={2}>
              <SectionHeader title={year} />
            </Column>
            <Column>
              {nodes[year].map(
                node =>
                  !node.frontmatter.isDummyFile && (
                    <NewsCardBase key={node.id}>
                      <Card as={Link} to={`/news/${node.frontmatter.slug}/`}>
                        <NewsTitle>{node.frontmatter.title}</NewsTitle>
                        <NewsExcerpt>{node.excerpt}</NewsExcerpt>
                        <NewsDate>{node.frontmatter.publishedAt}</NewsDate>
                      </Card>
                    </NewsCardBase>
                  )
              )}
            </Column>
          </Row>
        </Container>
      ))}
      <CareersBanner />
    </>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___publishedAt] }
      filter: { fields: { collectionType: { eq: "news" } } }
    ) {
      edges {
        node {
          id
          excerpt(truncate: true, pruneLength: 100)
          frontmatter {
            isDummyFile
            publishedAt: publishedAt(formatString: "YYYY/M/D")
            year: publishedAt(formatString: "YYYY")
            slug
            title
          }
        }
      }
    }
  }
`;

const NewsCardBase = styled.div`
  padding: 0 0 24px;
`;

const NewsTitle = styled.div`
  color: ${({ theme }) => theme.colors.main};
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 2px;
`;

const NewsExcerpt = styled.div`
  color: ${({ theme }) => theme.colors.normal};
  margin: 0 0 2px;
`;

const NewsDate = styled.div`
  color: ${({ theme }) => theme.colors.muted};
`;
